import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import siteImage from "../images/smartscalesync.png";
import siteImageTwitter from "../images/smartscalesync_twitter.png";

const SEO = ({ title, description, pathname }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, defaultDescription, siteUrl }
      }
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        url: `${siteUrl}${pathname || "/"}`
      };

      return (
        <>
          <Helmet title={seo.title}>
            <meta name="description" content={seo.description} />
            <meta
              name="keywords"
              content={
                "smart scale, sync, weight, garmin connect, withings, strava, fitbit, trainerroad, polar flow, concept2 logbook, peloton"
              }
            />
            {seo.url && <meta property="og:url" content={seo.url} />}
            <meta property="og:type" content="website" />
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            <meta property="og:image" content={`${siteUrl}${siteImage}`} />
            <meta property="og:image:type" content={"image/png"} />
            <meta property="og:image:width" content={"1078"} />
            <meta property="og:image:height" content={"926"} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta
              name="twitter:image"
              content={`${siteUrl}${siteImageTwitter}`}
            />
            <meta name="twitter:site" content="@smartscalesync" />
            <link
              href="https://fonts.googleapis.com/css?family=Open+Sans:300,400|Roboto:700"
              rel="stylesheet"
            />
          </Helmet>
        </>
      );
    }}
  />
);

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
  image: PropTypes.string
};

SEO.defaultProps = {
  title: null,
  description: null,
  pathname: null,
  image: null
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`;
