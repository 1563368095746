import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

const FooterDiv = styled.div`
  margin: 2rem 0;
  text-align: center;
  font-size: 0.8rem;
  background-color: rgb(249, 249, 249);
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const FooterP = styled.p`
  margin: 0 auto;
  width: 90%;
  max-width: 1145px;
  color: rgb(153, 153, 153);
`;

const FooterLink = styled(Link)`
  margin: 0 5px;
  text-decoration: none;
  color: rgb(153, 153, 153);

  &:hover {
    border-bottom: 1px solid #383838;
  }
`;

export default class Footer extends React.Component {
  render() {
    return (
      <FooterDiv>
        <FooterP>contact@smartscalesync.com</FooterP>
        <div>
          <FooterLink to="/privacy-policy/">Privacy Policy</FooterLink>
          <FooterLink to="/terms-of-service/">Terms of Service</FooterLink>
        </div>
        <FooterP>{`© ${new Date()
          .getFullYear()
          .toString()} SmartScaleSync - Renschler Labs LLC`}</FooterP>
      </FooterDiv>
    );
  }
}
