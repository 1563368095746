import netlifyIdentity from "netlify-identity-widget";
import { navigate } from "gatsby";

// helpful for debugging netlify identity
const logAuth =
  process.env.NODE_ENV && process.env.NODE_ENV === "development" && false; // set to true to turn on logging

const clog = (...args) => logAuth && console.log(...args);
// helpful for debugging netlify identity

export const isBrowser = () => typeof window !== "undefined";

export const initAuth = () => {
  //console.log(`init auth ${loc}`);
  if (isBrowser() && !window.netlifyIdentity) {
    // console.log(window.netlifyIdentity);
    //console.log("inside init auth");
    window.netlifyIdentity = netlifyIdentity;
    // You must run this once before trying to interact with the widget
    netlifyIdentity.init();
    netlifyIdentity.on("close", () => {
      if (isLoggedIn() && !document.URL.includes("account")) {
        console.log(
          "on home page and logged in, so refreshing to settings page"
        );
        navigate("/account/");
      } else {
        if (!isLoggedIn()) {
          console.log("not logged in");
        } else {
          console.log(`not navigating to settings because on ${document.URL}`);
        }
      }
    });
  }
};

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("netlifyUser")
    ? JSON.parse(window.localStorage.getItem("netlifyUser"))
    : {};

const setUser = user =>
  window.localStorage.setItem("netlifyUser", JSON.stringify(user));

export const handleLogin = callback => {
  clog("isLoggedIn check from handleLogin", isLoggedIn());
  if (isLoggedIn()) {
    clog("logged in");
    callback(getUser());
  } else {
    //console.log("FIRED!");
    clog("logging in...");
    netlifyIdentity.open();
    netlifyIdentity.on("login", user => {
      clog("logged in!", { user });
      setUser(user);
      if (window.heap) {
        //console.log("heap identify");
        window.heap.identify(window.netlifyIdentity.currentUser().email);
      }
      callback(user);
    });
  }
};

export const handleSignup = callback => {
  clog("isLoggedIn check from handleLogin", isLoggedIn());
  if (isLoggedIn()) {
    clog("logged in");
    callback(getUser());
  } else {
    //console.log("FIRED!");
    clog("logging in...");
    netlifyIdentity.open("signup");
    netlifyIdentity.on("login", user => {
      clog("logged in!", { user });
      setUser(user);
      callback(user);
    });
  }
};

export const isLoggedIn = () => {
  if (!isBrowser()) return false;

  if (!window.netlifyIdentity) initAuth();

  //const user1 = getUser();
  const user = netlifyIdentity.currentUser();
  //console.log("user1", user1);
  //console.log("user2", user2);
  //console.log(window.location.pathname);
  clog("isLoggedIn check", { user });
  return !!user;
};

export const logout = callback => {
  netlifyIdentity.logout();
  netlifyIdentity.on("logout", () => {
    window.sessionStorage.clear();
    setUser({});
    if (window.heap) {
      //console.log("heap identity reset");
      window.heap.resetIdentity();
    }
    callback();
  });
};
