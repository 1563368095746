import React from "react";
import { Link, navigate } from "gatsby";
import { handleLogin, logout, isLoggedIn } from "../account/services/auth";
import styled from "@emotion/styled";

const Banner = styled.div`
  height: 2rem;
  line-height: 1rem;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  /* background-color: rgb(255, 61, 48); */
  background-color: rgb(255, 43, 97);
  color: white;
  padding: 1px;

  @media (max-width: 680px) {
    height: 3rem;
  }

  @media (max-width: 470px) {
    height: 4rem;
  }

  @media (max-width: 390px) {
    height: 5rem;
  }
`;

const NavDiv = styled.div`
  background-color: #ffffff;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  margin: 10px 5px;

  &.banner {
    margin: 0px 5px;
  }
`;

const StyledH1 = styled.h1`
  display: flex;
  @media (max-width: 600px) {
    font-size: 1.3rem;
  }
`;

const HomeLink = styled(Link)`
  text-decoration: none;
  color: #222222;
  margin: 10px 5px;
  font-family: "Roboto", serif;
`;

const NavList = styled.ul`
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
`;

const ListItem = styled.li`
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #222222;
  border: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;

  &:hover {
    border-bottom: 1px solid #383838;
  }
`;

const ButtonLi = styled.li`
  margin: 0 10px;
  padding: 10px 15px;
  min-width: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #cccccc;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;

  &:hover {
    border: 1px solid #383838;
  }

  &.disabledNow {
    border: 1px solid #cccccc;
    color: #cccccc;
  }
`;

const activeLinkStyle = {
  fontWeight: "bold",
  borderBottom: "1px solid rgb(0, 158, 255)"
};

export default class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      loggedIn: false
    };
    this.logoutSubmit = this.logoutSubmit.bind(this);
    this.loginSubmit = this.loginSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      loggedIn: isLoggedIn()
    });
  }

  logoutSubmit(e) {
    e.preventDefault();
    this.setState({ disabled: true, loggedIn: false }, () => {
      logout(() => {
        navigate("/");
      });
    });
  }

  loginSubmit(e) {
    e.preventDefault();
    handleLogin(() => {
      this.setState({ loggedIn: true });
      navigate("/account/");
    });
  }

  render() {
    return (
      <>
        <NavDiv className="banner">
          {/* <NavDiv> */}
          <StyledH1>
            <HomeLink to="/">SmartScaleSync</HomeLink>
          </StyledH1>
          <NavList>
            <ListItem>
              <StyledLink
                to="/about/"
                activeStyle={activeLinkStyle}
                partiallyActive={true}
              >
                About
              </StyledLink>
            </ListItem>
            {this.state.loggedIn ? (
              <ListItem>
                <StyledLink
                  to="/account/"
                  activeStyle={activeLinkStyle}
                  partiallyActive={true}
                >
                  Settings
                </StyledLink>
              </ListItem>
            ) : null}
            {this.state.loggedIn ? (
              <ButtonLi
                className={this.state.disabled && "disabledNow"}
                onClick={this.logoutSubmit}
              >
                Log out
              </ButtonLi>
            ) : (
              <ButtonLi onClick={this.loginSubmit}>Log in</ButtonLi>
            )}
          </NavList>
        </NavDiv>
        <Banner>
          This is the legacy site. Our new site is live at
          https://smartscalesync.com but does not support Garmin scales.
        </Banner>
      </>
    );
  }
}
