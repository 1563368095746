import React from "react";
import Nav from "./nav";
import Footer from "./footer";
import styled from "@emotion/styled";
import SEO from "./seo";

const TrimmedWidthDiv = styled.div`
  margin: 0 auto;
  width: 90%;
  max-width: 1145px;
  text-align: center;
`;
const ContentDiv = styled.div`
  margin: 2rem auto;
`;

export default class Layout extends React.Component {
  render() {
    return (
      <div>
        <SEO />
        <TrimmedWidthDiv>
          <Nav />
          <ContentDiv>{this.props.children}</ContentDiv>
        </TrimmedWidthDiv>
        <Footer />
      </div>
    );
  }
}
